import React from "react"
import Layout from "../components/layouts"
import sent from '../images/sent.svg'

export default () => (
  <Layout>
    <div className="not-found">
		<img src={sent} alt="Trimis"></img>
  		<h1>Mesajul a fost trimis</h1>
  		<h3>Vă mulțumim că ne-ați contactat</h3>
  		<p><a href="/">Înapoi la pagina principală</a></p>
  	</div>
  </Layout>
)
